import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ResetPW } from '../../components';
import { resetPw, resetMsg, setErr } from '../../redux/modules/user';

const ResetPWContainer = connect(
  state => ({
    user: state.user
  }),
  { resetPw, resetMsg, setErr }
)(ResetPW);

export default withRouter(ResetPWContainer);
