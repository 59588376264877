import React from 'react'
import { ClipLoader } from 'react-spinners';

import './Spinner.css'

const Spinner = ({ loading }) => (
  <div className={loading.length > 0 ? 'spinner' : 'spinner hidden'}>
    <ClipLoader
      sizeUnit={"px"}
      size={80}
      color={'#000000'}
      loading={loading.length > 0}
    />
  </div>
)

export default Spinner
