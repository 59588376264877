import { connect } from 'react-redux';
import { Users } from '../../components';
import { getUsers, editUser, deleteUser } from '../../redux/modules/users';

const UsersContainer = connect(
  state => ({
    users: state.users,
    user: state.user
  }),
  { getUsers, editUser, deleteUser }
)(Users);

export default UsersContainer;
