import React, { Component } from 'react'
import { UserRow } from '../'

import './Users.css'

class Users extends Component {
  componentDidMount () {
    const { getUsers } = this.props
    const { token } = this.props.user
    getUsers(token)
  }

  render () {
    const { users, editUser, deleteUser } = this.props
    const { token } = this.props.user
    return (
      <div className='users'>
        <h1>Users</h1>
        <hr />
        <div className='users-list-header'>
          <div>Name</div>
          <div>Role</div>
          <div>Email</div>
        </div>
        {users.map((user, i) => (
          <UserRow key={i} user={user} token={token} editUser={editUser} deleteUser={deleteUser} />
        ))}
      </div>
    )
  }
}

export default Users
