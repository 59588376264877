import React, { Component, Fragment } from 'react';
import { Dropdown } from '../../'

import './DocumentRowDropdown.css';

class DocumentRowDropdown extends Component {
  render () {
    const { hideDropdown, selectedFile, user, togglePopup, path, type, toggleConfirmationWindow } = this.props
    return (
      <Fragment>
        <Dropdown
          options={[
            {
              name: type === 'file' ? 'Rename File' : 'Rename Folder',
              function: () => togglePopup()
            },
            {
              name: type === 'file' ? 'Delete File' : 'Delete Folder',
              function: () => toggleConfirmationWindow(),
              topBorder: true
            }
          ]}
          hideDropdown={() => hideDropdown()}
          outsideClickIgnoreClass='users-list-dropdown-button-active'
          selectedFile={selectedFile}
          user={user}
          path={path}
        />
      </Fragment>
    )
  }
}

export default DocumentRowDropdown
