import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import './ForgotPW.css'

class ForgotPW extends Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
    }
  }

  handleSubmit (e) {
    e.preventDefault()
    const { email } = this.state
    const { forgetPw } = this.props
    forgetPw(email)
  }

  componentWillUnmount () {
    this.props.resetMsg()
  }

  render () {
    const { err, msg, hideResetPw } = this.props.user
    const { email } = this.state
    return (
      <div className='forgot-pw'>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className='forgot-pw-form'>
            <label>
              EMAIL ADDRESS
              <input type='text' value={email} onChange={(e) => this.setState({ email: e.target.value })} />
            </label>
            {err && <p className='form-error'>{err}</p>}
            {msg && <p className='form-msg'>{msg}</p>}
            {!hideResetPw && <button type='submit' className='button'>RESET MY PASSWORD</button>}
            {!hideResetPw && <NavLink to='/'>
              <p>Back</p>
            </NavLink>}
          </div>
        </form>
      </div>
    )
  }
}

export default ForgotPW
