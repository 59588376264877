import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { DocumentRow } from '../'
import { Icon } from 'react-icons-kit'
import {ic_play_arrow} from 'react-icons-kit/md/ic_play_arrow'

import './Folder.css'

class Folder extends Component {
  componentDidMount () {
    const { user, viewFolder, path } = this.props
    user.user._id && viewFolder(user.token, path || '')
  }

  componentDidUpdate (prevProps) {
    const { user, viewFolder, path, folder } = this.props
    if (prevProps.path !== path || folder.newFolder) {
      user.user._id && viewFolder(user.token, path || '')
    }
  }

  componentWillUnmount () {
    this.props.resetPath()
  }

  render () {
    const { path, folder, viewport, getFile, renameFile, renameFolder, user, deleteFile, deleteFolder } = this.props
    return (
      <div className='folder'>
        <h1>
          {viewport === 'mobile' && <span>
            {path || 'Home'}
          </span>}
          {viewport === 'desktop' && <span>
            <NavLink to='/'>Home</NavLink>
          </span>}
          {path && viewport === 'desktop' && path.split('/').map((pathSection, i) => (
            <Fragment key={i}>
              <Icon icon={ic_play_arrow} />
              <span>
                <NavLink to={`/view/${encodeURIComponent(path.split('/').slice(0, i + 1).join('/'))}`}>
                  {pathSection}
                </NavLink>
              </span>
            </Fragment>
          ))}
        </h1>
        <hr />
        <div className='folder-header'>
          <div>Name</div>
          <div>Last Modified</div>
          <div>Size</div>
        </div>
        {folder.documents.filter(doc => doc.name.length > 0).map((doc, i) => (
          <DocumentRow key={i} document={doc} path={path} token={user.token} getFile={getFile} renameFile={renameFile} renameFolder={renameFolder} role={user.user.role} deleteFile={deleteFile} deleteFolder={deleteFolder} />
        ))}
      </div>
    )
  }
}

export default Folder
