import { connect } from 'react-redux';
import { Login } from '../../components';
import { loginUser, resetMsg } from '../../redux/modules/user';

const LoginContainer = connect(
  state => ({
    user: state.user
  }),
  { loginUser, resetMsg }
)(Login);

export default LoginContainer;
