import { connect } from 'react-redux';
import { DocumentRowDropdown } from '../../components';
import { deleteFile } from '../../redux/modules/file';
import { deleteFolder } from '../../redux/modules/folder';

const DocumentRowDropdownContainer = connect(
  state => ({
    user: state.user,
  }),
  { deleteFile, deleteFolder }
)(DocumentRowDropdown);

export default DocumentRowDropdownContainer;
