import { combineReducers } from 'redux';
import user from './user';
import sidebar from './sidebar';
import viewport from './viewport';
import users from './users';
import folder from './folder';
import status from './status';

export default combineReducers({
  user,
  sidebar,
  viewport,
  users,
  folder,
  status
})
