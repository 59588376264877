import React, { Component } from 'react'

import './ResetPW.css'

class ResetPW extends Component {
  constructor (props) {
    super(props)

    this.state = {
      password: '',
      confirmPassword: '',
      err: ''
    }
  }

  // componentWillUnmount () {
  //   this.props.resetMsg()
  // }

  componentDidUpdate (prevProps) {
    if (this.props.user.resetPwSuccess) {
      this.props.history.push('/')
    }
  }

  handleSubmit (e) {
    e.preventDefault()
    const { password, confirmPassword } = this.state
    const { resetPw, token, setErr } = this.props
    if (password !== confirmPassword) setErr('Passwords do not match.')
    else resetPw(token, password, confirmPassword)
  }

  render () {
    const { err } = this.props.user
    const { password, confirmPassword } = this.state
    return (
      <div className='reset-pw'>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className='reset-pw-form'>
            <label>
              PASSWORD
              <input type='password' value={password} onChange={(e) => this.setState({ password: e.target.value })} required />
            </label>
            <label>
              CONFIRM PASSWORD
              <input type='password' value={confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })} required />
            </label>
            {err && <p className='form-error'>{err}</p>}
            <button type='submit' className='button'>CHANGE MY PASSWORD</button>
          </div>
        </form>
      </div>
    )
  }
}

export default ResetPW
