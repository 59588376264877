import axios from 'axios'
import { toast } from "react-toastify";
import { startLoading, stopLoading } from './status'
import { getUsers } from './users'

const REQ_LOGIN_USER = 'oms-frontend/user/REQ_LOGIN_USER'
const REC_LOGIN_USER = 'oms-frontend/user/REC_LOGIN_USER'
const LOGOUT_USER = 'oms-frontend/user/LOGOUT_USER'
const REQ_CREATE_USER = 'oms-frontend/user/REQ_CREATE_USER'
const REC_CREATE_USER = 'oms-frontend/user/REC_CREATE_USER'
const REQ_FORGET_PW = 'oms-frontend/user/REQ_FORGET_PW'
const REC_FORGET_PW = 'oms-frontend/user/REC_FORGET_PW'
const REQ_RESET_PW = 'oms-frontend/user/REQ_RESET_PW'
const REC_RESET_PW = 'oms-frontend/user/REC_RESET_PW'
const RESET_MSG = 'oms-frontend/user/RESET_MSG'
const SET_ERR = 'oms-frontend/user/SET_ERR'
const REQ_VERIFY_USER = 'oms-frontend/user/REQ_VERIFY_USER'
const REC_VERIFY_USER = 'oms-frontend/user/REC_VERIFY_USER'

const initialState = {
  user: {},
  token: sessionStorage.getItem('token'),
  err: '',
  msg: '',
  resetPwSuccess: false,
  hideResetPw: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_MSG:
      return {
        ...state,
        ...{
          err: '',
          msg: ''
        }
      }
    case REC_LOGIN_USER:
      return {
        ...state,
        ...{
          user: action.user,
          token: action.token,
          err: action.err,
          msg: '',
        }
      }
    case LOGOUT_USER:
      return {
        user: {},
        token: null,
        loginSuccess: false,
        err: '',
        msg: '',
      }
    case REC_FORGET_PW:
      return {
        ...state,
        ...{
          err: action.err,
          msg: action.msg,
          hideResetPw: true
        }
      }
    case REC_RESET_PW:
      return {
        ...state,
        ...{
          err: action.err,
          msg: action.msg,
          resetPwSuccess: action.resetPwSuccess
        }
      }
    case REC_VERIFY_USER:
      return {
        ...state,
        ...{
          user: action.user
        }
      }
    case SET_ERR:
      return {
        ...state,
        ...{
          err: action.err
        }
      }
    default:
      return state
  }
}

export const resetMsg = () => ({
  type: RESET_MSG
})

export const setErr = err => ({
  type: SET_ERR,
  err
})

export const reqLoginUser = () => ({
  type: REQ_LOGIN_USER,
})

export const recLoginUser = (user, token, err) => ({
  type: REC_LOGIN_USER,
  user,
  token,
  err
})

export const loginUser = (user) => {
  return dispatch => {
    dispatch(reqLoginUser())
    dispatch(startLoading())
    return axios.post(`${process.env.REACT_APP_API_URL}/users/login`, {
      email: user.email.toLowerCase(),
      password: user.password,
      role: user.role
    })
    .then(res => {
      if (res.data.success) {
        dispatch(recLoginUser(res.data.foundUser, res.data.token))
        sessionStorage.setItem('token', res.data.token)
        dispatch(stopLoading())
      } else {
        dispatch(recLoginUser({}, null, res.data.err))
        dispatch(stopLoading())
      }
    })
  }
}

export const logoutUser = () => {
  sessionStorage.clear()
  return {
    type: LOGOUT_USER
  }
}

export const reqCreateUser = () => ({
  type: REQ_CREATE_USER
})

export const recCreateUser = () => ({
  type: REC_CREATE_USER
})

export const createUser = (token, user) => {
  return dispatch => {
    dispatch(reqCreateUser())
    dispatch(startLoading())
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/users`,
      data: {
        email: user.email,
        name: user.name,
        role: user.role
      },
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.data.success) {
          dispatch(recCreateUser())
          toast.success('User successfully created.')
          dispatch(getUsers(token))
          dispatch(stopLoading())
        } else {
          toast.error(res.data.err)
          dispatch(stopLoading())
        }
      })
  }
}

export const reqForgetPw = () => ({
  type: REQ_FORGET_PW
})

export const recForgetPw = (err, msg) => ({
  type: REC_FORGET_PW,
  err,
  msg
})

export const forgetPw = (email, isAdminRequest) => {
  return dispatch => {
    dispatch(reqForgetPw())
    dispatch(startLoading())
    return axios.post(`${process.env.REACT_APP_API_URL}/users/reset`, {
      email: email
    })
    .then(res => {
      if (res.data.success && !isAdminRequest) {
        dispatch(recForgetPw('', 'An email has been sent to the above email address, please follow the instructions in that email to change your password.'))
        dispatch(stopLoading())
      } else if (res.data.success && isAdminRequest) {
        dispatch(recForgetPw('', ''))
        dispatch(stopLoading())
        toast.success(`An email with instructions to reset password has been sent to ${email}`)
      } else {
        dispatch(recForgetPw(res.data.err))
        dispatch(stopLoading())
      }
    })
  }
}

export const reqResetPw = () => ({
  type: REQ_RESET_PW
})

export const recResetPw = (err, msg, resetPwSuccess) => ({
  type: REC_RESET_PW,
  err,
  msg,
  resetPwSuccess
})

export const resetPw = (resetToken, password, confirmPassword) => {
  return dispatch => {
    dispatch(reqResetPw())
    dispatch(startLoading())
    return axios({
      method: 'patch',
      url: `${process.env.REACT_APP_API_URL}/users/reset/${resetToken}`,
      data: {
        password,
        confirmPassword
      }
    })
      .then(res => {
        if (res.data.success) {
          dispatch(recResetPw('', '', true))
          dispatch(stopLoading())
          toast.success('Successfully changed password, please log in.')
        } else {
          dispatch(recResetPw(res.data.err))
          dispatch(stopLoading())
        }
      })
  }
}

export const reqVerifyUser = () => ({
  type: REQ_VERIFY_USER
})

export const recVerifyUser = (user = {}) => ({
  type: REC_VERIFY_USER,
  user
})

export const verifyUser = token => {
  return dispatch => {
    dispatch(reqVerifyUser())
    return axios.get(`${process.env.REACT_APP_API_URL}/users/${token}`)
      .then(res => {
        if (res.data.success) {
          dispatch(recVerifyUser(res.data.foundUser))
        } else {
          dispatch(logoutUser())
        }
      })
      .catch(err => {
        dispatch(logoutUser())
      })
  }
}
