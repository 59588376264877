import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'

import './Dropdown.css'

class Dropdown extends Component {
  handleClickOutside (e) {
    this.props.hideDropdown()
  }

  render () {
    const { options, user, selectedUser, selectedFile, path } = this.props
    const doc = selectedUser ? selectedUser.id : (path ? path + '/' + selectedFile.name : selectedFile.name)
    return (
      <div className='dropdown'>
        {options.map((option, i) => (
          <div className='dropdown-option' key={i}>
            {option.topBorder && <hr />}
            <span onClick={() => {
              this.props.hideDropdown()
              option.function(user.token, doc)
            }}>{option.name}</span>
          </div>
        ))}
      </div>
    )
  }
}

export default onClickOutside(Dropdown)
