import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { Icon } from 'react-icons-kit'
import {ic_play_arrow} from 'react-icons-kit/md/ic_play_arrow'
import {ic_add_circle_outline} from 'react-icons-kit/md/ic_add_circle_outline'
import { NavLink } from 'react-router-dom'
import { SidebarDropdown, PopUpForm } from '../'

import './Sidebar.css'

class Sidebar extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dropdownActive: false,
      popupActive: false,
      popupType: null,
    }
  }

  handleClickOutside () {
    const { toggleSidebar, sidebar } = this.props
    if (sidebar) toggleSidebar(false)
  }

  hideDropdown () {
    this.setState({
      dropdownActive: false
    })
  }

  hidePopup () {
    this.setState({
      popupActive: false
    })
  }

  togglePopup (type) {
    this.setState({
      popupActive: true,
      popupType: type
    })
  }

  render () {
    const { sidebar, logoutUser, viewport, history, createUser, createFolder, uploadFile, toggleSidebar } = this.props
    const { dropdownActive, popupActive, popupType } = this.state
    const { user, token } = this.props.user
    const { path } = this.props.folder
    const isAdmin = user.role === 'admin'
    return (
      <div className={sidebar ? 'sidebar active' : 'sidebar'}>
        <span className='sidebar-greeting'>{user.name}</span>
        {isAdmin && viewport === 'desktop' && (
          <div style={{ position: 'relative' }}>
            <div className='sidebar-add button' onClick={() => this.setState({ dropdownActive: !dropdownActive })}>
              <Icon icon={ic_add_circle_outline} size={24} /> New
            </div>
            <SidebarDropdown hideDropdown={() => this.hideDropdown()} togglePopup={(type) => this.togglePopup(type)} uploadFile={uploadFile} path={path} token={token} className={dropdownActive ? 'sidebar-dropdown show' : 'sidebar-dropdown'}/>
          </div>
        )}
        <NavLink to='/'>
          <span className='nav-link' style={history.location.pathname === '/' ? { color: '#4267b2' } : {}} onClick={() => toggleSidebar(false)}>
            Home
          </span>
        </NavLink>
        {isAdmin && viewport === 'desktop'
          && (
            <NavLink to='/users'>
              <span className='nav-link' style={history.location.pathname === '/users' ? { color: '#4267b2' } : {}}>
                Users
              </span>
            </NavLink>
          )
        }
        <span className='nav-link logout' onClick={() => logoutUser()}>Log out</span>
        {popupActive && popupType === 'user' && <PopUpForm
          token={token} newDoc={{ name: '', email: '', role: 'user' }} close={() => this.hidePopup()} submit={createUser} inputs={[
            {
              name: 'name',
              type: 'text',
            },
            {
              name: 'email',
              type: 'text',
            },
            {
              name: 'role',
              type: 'select',
              options: ['user', 'admin']
            }
          ]}
        />}
        {popupActive && popupType === 'folder' && <PopUpForm
          token={token} newDoc={{ name: '' }} close={() => this.hidePopup()} path={path} submit={createFolder} inputs={[
            {
              name: 'name',
              type: 'text',
            }
          ]}
        />}
      </div>
    )
  }
}

export default onClickOutside(Sidebar)
