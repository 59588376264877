import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import logo from '../../assets/oms-logo.jpg'
import './Login.css'

class Login extends Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      role: 'user'
    }
  }

  handleLogin (e) {
    e.preventDefault()
    const { email, password, role } = this.state
    const { loginUser } = this.props
    loginUser({
      email,
      password,
      role
    })
  }

  componentWillUnmount () {
    this.props.resetMsg()
  }

  render () {
    const { email, password, role } = this.state
    const { err, msg } = this.props.user
    return (
      <div className='login'>
        <form onSubmit={(e) => this.handleLogin(e)}>
          <div className='login-form'>
            <img src={logo} alt='oms-logo' />
            <span>Service Reports</span>
            <label>
              EMAIL ADDRESS
              <input type='text' value={email} onChange={(e) => this.setState({ email: e.target.value })} />
            </label>
            <label>
              PASSWORD
              <input type='password' value={password} onChange={(e) => this.setState({ password: e.target.value })} />
            </label>
            <label>
              ROLE
              <select value={role} onChange={(e) => this.setState({ role: e.target.value })}>
                <option value='user'>User</option>
                <option value='admin'>Admin</option>
              </select>
            </label>
            {err && <p className='form-error'>{err}</p>}
            {msg && <p className='form-msg'>{msg}</p>}
            <button type='submit' className='button'>LOG IN</button>
            <NavLink to='/forgot-password'>
              <p>Forgot password?</p>
            </NavLink>
          </div>
        </form>
      </div>
    )
  }
}

export default Login
