import React, { Component } from 'react'
import { Icon } from 'react-icons-kit'
import {ic_close} from 'react-icons-kit/md/ic_close'

import './PopUpForm.css'

class PopUpForm extends Component {
  constructor (props){
    super(props)

    this.state = props.doc || props.fileFolder || props.newDoc
  }

  render () {
    const { inputs, close, submit, token, doc, path, fileFolder } = this.props
    return (
      <div className='pop-up-background'>
        <div className='pop-up-form'>
          <div className='pop-up-form-close-button' onClick={() => close()}>
            <Icon icon={ic_close} />
          </div>
          <form onSubmit={(e) => {
            e.preventDefault()
            if (doc) submit(token, doc.id, this.state, path)
            else if (fileFolder) submit(token, path ? encodeURIComponent(path + '/' + fileFolder.name) : encodeURIComponent(fileFolder.name), path ? encodeURIComponent(path + '/' + this.state.name) : encodeURIComponent(this.state.name))
            else submit(token, this.state, path)
            close()
          }}>
            {inputs.map((input, i) => {
              if (input.type === 'text') {
                return (
                  <label key={i}>
                    {input.name}
                    <input key={i} type='text' value={this.state[input.name]} onChange={(e) => this.setState({ [input.name]: e.target.value })} required />
                  </label>
                )
              } else {
                return (
                  <label key={i}>
                    {input.name}
                    <select value={this.state[input.name]} onChange={(e) => this.setState({ [input.name]: e.target.value })}>
                      {input.options.map((option, i) => (
                        <option className='pop-up-form-option' key={i} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </label>
                )
              }
            })}
            <button type='submit' className='button'>Submit</button>
          </form>
        </div>
      </div>
    )
  }
}

export default PopUpForm
