import axios from 'axios'
import { toast } from "react-toastify";
import { startLoading, stopLoading } from './status'

const REQ_GET_USERS = 'oms-frontend/users/REQ_GET_USERS'
const REC_GET_USERS = 'oms-frontend/users/REC_GET_USERS'
const REQ_DELETE_USER = 'oms-frontend/users/REQ_DELETE_USER'
const REC_DELETE_USER = 'oms-frontend/users/REC_DELETE_USER'
const REQ_EDIT_USER = 'oms-frontend/users/REQ_EDIT_USER'
const REC_EDIT_USER = 'oms-frontend/users/REC_EDIT_USER'

export default (state = [], action) => {
  switch (action.type) {
    case REC_GET_USERS:
      return action.users
    case REC_DELETE_USER:
      return state.filter(user => user.id !== action.id)
    default:
      return state
  }
}

export const reqGetUsers = () => ({
  type: REQ_GET_USERS
})

export const recGetUsers = users => ({
  type: REC_GET_USERS,
  users
})

export const getUsers = token => {
  return dispatch => {
    dispatch(reqGetUsers())
    dispatch(startLoading())
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/users`,
      headers: {
        'Authorization': token
      }
    })
      .then(res => {
        if (res.data.success) {
          dispatch(recGetUsers(res.data.users))
          dispatch(stopLoading())
        } else {
          alert(res.data.err)
        }
      })
  }
}

export const reqDeleteUser = () => ({
  type: REQ_DELETE_USER
})

export const recDeleteUser = id => ({
  type: REC_DELETE_USER,
  id
})

export const deleteUser = (token, id) => {
  return dispatch => {
    dispatch(reqDeleteUser())
    dispatch(startLoading())
    return axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/users/${id}`,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.data.success) {
          dispatch(recDeleteUser(id))
          toast.success('User successfully deleted')
          dispatch(stopLoading())
        } else {
          dispatch(stopLoading())
        }
      })
  }
}

export const reqEditUser = () => ({
  type: REQ_EDIT_USER
})

export const recEditUser = () => ({
  type: REC_EDIT_USER
})

export const editUser = (token, id, object) => {
  return dispatch => {
    dispatch(reqEditUser())
    dispatch(startLoading())
    return axios({
      method: 'patch',
      url: `${process.env.REACT_APP_API_URL}/users/${id}`,
      data: object,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.data.success) {
          dispatch(recEditUser())
          dispatch(getUsers(token))
          toast.success('User successfully edited')
          dispatch(stopLoading())
        } else {
          dispatch(stopLoading())
        }
      })
  }
}
