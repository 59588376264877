import React, { Component } from 'react';
import { Dropdown } from '../../'

import './UserRowDropdown.css';

class UserRowDropdown extends Component {
  render () {
    const { hideDropdown, selectedUser, user, togglePopup, toggleConfirmationWindow, forgetPw } = this.props
    return (
      <Dropdown
        options={[
          {
            name: 'Edit User',
            function: () => togglePopup()
          },
          {
            name: 'Reset Password',
            function: () => forgetPw(selectedUser.email, true)
          },
          {
            name: 'Delete User',
            function: () => toggleConfirmationWindow(false),
            topBorder: true
          }
        ]}
        hideDropdown={() => hideDropdown()}
        outsideClickIgnoreClass='users-list-dropdown-button-active'
        selectedUser={selectedUser}
        user={user}
      />
    )
  }
}

export default UserRowDropdown
