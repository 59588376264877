import { connect } from 'react-redux';
import { ForgotPW } from '../../components';
import { forgetPw, resetMsg } from '../../redux/modules/user';

const ForgotPWContainer = connect(
  state => ({
    user: state.user
  }),
  { forgetPw, resetMsg }
)(ForgotPW);

export default ForgotPWContainer;
