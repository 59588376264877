import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { SidebarContainer, NavbarContainer, LoginContainer, ForgotPWContainer, UsersContainer, FolderContainer, ResetPWContainer, SpinnerContainer } from './containers'
import './App.css'
import "react-toastify/dist/ReactToastify.css";

class App extends Component {
  componentDidMount () {
    const { token } = this.props.user
    if (token) this.props.verifyUser(token)
    this.props.changeViewport(window.innerWidth)
  }

  render () {
    const { sidebar} = this.props
    const { token, user } = this.props.user
    if (!token) return (
      <Router>
        <div>
          <Switch>
            <Route path='/forgot-password' component={ForgotPWContainer} />
            <Route path='/reset/:token' render={({ match }) => <ResetPWContainer token={match.params.token} />} />
            <Route path='/' component={LoginContainer} />
          </Switch>
          <SpinnerContainer />
          <ToastContainer autoClose={3000} hideProgressBar />
        </div>
      </Router>
    )
    else if (!user._id) return (
      <div />
    )
    else return (
      <Router>
        <div className='App'>
          <div className={sidebar ? 'sidebar-gray-background' : 'sidebar-gray-background hidden'} />
          <NavbarContainer />
          <SidebarContainer />
          <div className='app-body'>
            {user.role === 'admin' && <Route path='/users' component={UsersContainer} />}
            <Route path='/' exact render={() => <FolderContainer path='' />} />
            <Route path='/view/:path' render={({ match }) => <FolderContainer path={decodeURIComponent(match.params.path)} />} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <SpinnerContainer />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
