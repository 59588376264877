import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Folder } from '../../components';
import { viewFolder, renameFolder, resetPath, deleteFolder } from '../../redux/modules/folder';
import { getFile, renameFile, deleteFile } from '../../redux/modules/file';

const FolderContainer = connect(
  state => ({
    folder: state.folder,
    user: state.user,
    viewport: state.viewport
  }),
  { viewFolder, getFile, renameFile, renameFolder, resetPath, deleteFolder, deleteFile}
)(Folder);

export default withRouter(FolderContainer);
