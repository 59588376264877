import { connect } from 'react-redux';
import { Navbar } from '../../components';
import { toggleSidebar } from '../../redux/modules/sidebar';

const NavbarContainer = connect(
  state => ({
    sidebar: state.sidebar,
    viewport: state.viewport,
    user: state.user
  }),
  { toggleSidebar },
)(Navbar);

export default NavbarContainer;
