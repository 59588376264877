import React, { Component } from 'react'
import { Icon } from 'react-icons-kit'
import {ic_more_horiz} from 'react-icons-kit/md/ic_more_horiz'
import { UserRowDropdownContainer } from '../../../containers'
import { PopUpForm, ConfirmationWindow } from '../../'

import './UserRow.css'

class UserRow extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dropdownActive: false,
      editFormActive: false,
      confirmationWindowActive: false
    }
  }

  toggleConfirmationWindow (bool) {
    this.setState({
      confirmationWindowActive: bool
    })
  }

  toggleEditUser () {
    this.setState({
      editFormActive: true
    })
  }

  clearEditUser () {
    this.setState({
      editFormActive: false
    })
  }

  hideDropdown () {
    this.setState({ dropdownActive: false })
  }

  render () {
    const { name, role, email, id } = this.props.user
    const { token, editUser, deleteUser } = this.props
    const { dropdownActive, editFormActive, confirmationWindowActive } = this.state
    return (
      <div className='users-list-row'>
        <div>{name}</div>
        <div>{role}</div>
        <div>{email}</div>
        <div className='users-list-dropdown'>
          <div className={dropdownActive ? 'users-list-dropdown-button-active' : 'users-list-dropdown-button'} onClick={() => this.setState({ dropdownActive: !dropdownActive })}>
            <Icon size={24} icon={ic_more_horiz} />
          </div>
          {dropdownActive && <UserRowDropdownContainer hideDropdown={() => this.hideDropdown()} togglePopup={() => this.toggleEditUser()} selectedUser={this.props.user} toggleConfirmationWindow={() => this.toggleConfirmationWindow(true)} />}
        </div>
        {editFormActive && <PopUpForm token={token} doc={this.props.user} close={() => this.clearEditUser()} submit={editUser} inputs={[
          {
            name: 'name',
            type: 'text'
          },
          {
            name: 'role',
            type: 'select',
            options: ['user', 'admin']
          }
        ]} />}
        {confirmationWindowActive && <ConfirmationWindow
          message='Are you sure you want to delete this user?'
          onSuccess={() => {
            deleteUser(token, id)
          }}
          onCancel={() => this.toggleConfirmationWindow(false)}
        />}
      </div>
    )
  }
}

export default UserRow
