import { connect } from 'react-redux';
import { UserRowDropdown } from '../../components';
import { deleteUser } from '../../redux/modules/users';
import { forgetPw } from '../../redux/modules/user';

const UserRowDropdownContainer = connect(
  state => ({
    user: state.user,
  }),
  { deleteUser, forgetPw }
)(UserRowDropdown);

export default UserRowDropdownContainer;
