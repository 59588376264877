const TOGGLE_SIDEBAR = 'oms-frontend/sidebar/TOGGLE_SIDEBAR'

export default (state = false, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return action.sidebar
    default:
      return state
  }
}

export const toggleSidebar = sidebar => ({
  type: TOGGLE_SIDEBAR,
  sidebar
})
