import axios from 'axios'
import { toast } from "react-toastify";
import { startLoading, stopLoading } from './status'
import { REC_UPLOAD_FILE, REC_DELETE_FILE, REC_RENAME_FILE } from './file'

const REQ_VIEW_FOLDER = 'oms-frontend/folder/REQ_VIEW_FOLDER'
const REC_VIEW_FOLDER = 'oms-frontend/folder/REC_VIEW_FOLDER'
const REQ_CREATE_FOLDER = 'oms-frontend/folder/REQ_CREATE_FOLDER'
const REC_CREATE_FOLDER = 'oms-frontend/folder/REC_CREATE_FOLDER'
const REQ_DELETE_FOLDER = 'oms-frontend/folder/REQ_DELETE_FOLDER'
const REC_DELETE_FOLDER = 'oms-frontend/folder/REC_DELETE_FOLDER'
const REQ_RENAME_FOLDER = 'oms-frontend/folder/REQ_RENAME_FOLDER'
const REC_RENAME_FOLDER = 'oms-frontend/folder/REC_RENAME_FOLDER'
const RESET_PATH = 'oms-frontend/folder/RESET_PATH'

const initialState = {
  path: '',
  documents: [],
  newFolder: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REC_VIEW_FOLDER:
      return {
        path: action.path,
        documents: action.documents,
        newFolder: false,
      }
    case REC_CREATE_FOLDER:
      return {
        ...state,
        ...{
          newFolder: true,
        }
      }
    case REC_UPLOAD_FILE:
      return {
        ...state,
        ...{
          newFolder: true,
        }
      }
    case REC_DELETE_FILE:
      return {
        ...state,
        ...{
          newFolder: true,
        }
      }
    case REC_DELETE_FOLDER:
      return {
        ...state,
        ...{
          newFolder: true,
        }
      }
    case REC_RENAME_FILE:
      return {
        ...state,
        ...{
          newFolder: true,
        }
      }
    case REC_RENAME_FOLDER:
      return {
        ...state,
        ...{
          newFolder: true,
        }
      }
    case RESET_PATH:
      return initialState
    default:
      return state
  }
}

export const resetPath = () =>({
  type: RESET_PATH
})

export const reqViewFolder = () => ({
  type: REQ_VIEW_FOLDER
})

export const recViewFolder = (path, documents) => ({
  type: REC_VIEW_FOLDER,
  path,
  documents
})

export const viewFolder = (token, path) => {
  return dispatch => {
    dispatch(reqViewFolder())
    dispatch(startLoading())
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/folders/view?name=${path}`,
      headers: {
        'Authorization': token
      }
    })
      .then(res => {
        if (!res.data.err) {
          const documents = res.data.Contents
            .map(doc => {
              if (!path) return doc
              else return {
                ...doc,
                ...{
                  Key: doc.Key.slice(path.length + 1)
                }
              }
            })
            .filter(doc => {
              return (!doc.Key.includes('/') || doc.Key.split('/')[1].length === 0) && doc.Key.length > 0
            })
            .map(doc => ({
              name: doc.Key.replace('/', ''),
              type: doc.Key.includes('/') ? 'folder' : 'file',
              size: doc.Size,
              lastModified: doc.LastModified
            }))
            .sort((a, b) => {
              if (a.type === 'folder' && b.type ==='file') {
                return -1
              } else if (a.type === 'file' && b.type === 'folder') {
                return 1
              } else {
                return 0
              }
            })
          dispatch(recViewFolder(path, documents))
          dispatch(stopLoading())
        } else {
          dispatch(recViewFolder())
          toast.error('Error encountered, please try again.')
          dispatch(stopLoading())
        }
      })
  }
}

export const reqCreateFolder = () => ({
  type: REQ_CREATE_FOLDER
})

export const recCreateFolder = () => ({
  type: REC_CREATE_FOLDER
})

export const createFolder = (token, folder, path) => {
  return dispatch => {
    dispatch(reqCreateFolder())
    dispatch(startLoading())
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/folders/create?name=${encodeURIComponent(path ? path + '/' : '')} ${encodeURIComponent(folder.name)}`,
      headers: {
        'Authorization': token
      }
    })
      .then(res => {
        if (!res.data.err) {
          dispatch(recCreateFolder())
          toast.success('Folder successfully created.')
          dispatch(stopLoading())
        } else {
          toast.error(res.data.err.error)
          dispatch(stopLoading())
        }
      })
  }
}

export const reqDeleteFolder = () => ({
  type: REQ_DELETE_FOLDER
})

export const recDeleteFolder = () => ({
  type: REC_DELETE_FOLDER
})

export const deleteFolder = (token, name) => {
  return dispatch => {
    dispatch(reqDeleteFolder())
    dispatch(startLoading())
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/folders/delete?name=${name}`,
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      if (!res.err) {
        dispatch(recDeleteFolder())
        toast.success('Successfully deleted 1 folder.')
        dispatch(stopLoading())
      } else {
        toast.error('Error encountered, please try again.')
        dispatch(stopLoading())
      }
    })
  }
}

export const reqRenameFolder = () => ({
  type: REQ_RENAME_FOLDER
})

export const recRenameFolder = () => ({
  type: REC_RENAME_FOLDER
})

export const renameFolder = (token, initialPath, destinationPath) => {
  return dispatch => {
    dispatch(reqRenameFolder())
    dispatch(startLoading())
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/folders/rename?initial-path=${initialPath}&destination-path=${destinationPath}`,
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      if (!res.data.err) {
        dispatch(recRenameFolder())
        toast.success('Successfully renamed 1 folder.')
        dispatch(stopLoading())
      } else {
        toast.error(res.data.err.error || res.data.err)
        dispatch(stopLoading())
      }
    })
  }
}
