import React from 'react'

import './ConfirmationWindow.css'

const ConfirmationWindow = ({
  onSuccess,
  onCancel,
  message
}) => (
  <div className='confirmation-window-background'>
    <div className='confirmation-window'>
      <span>{message}</span>
      <div className='confirmation-window-buttons'>
        <button onClick={() => onCancel()}>Cancel</button>
        <button onClick={() => {
          onSuccess()
          onCancel()
        }} className='button'>Submit</button>
      </div>
    </div>
  </div>
)

export default ConfirmationWindow
