import { connect } from 'react-redux';
import { Sidebar } from '../../components';
import { withRouter } from 'react-router-dom';
import { toggleSidebar } from '../../redux/modules/sidebar';
import { logoutUser, createUser } from '../../redux/modules/user';
import { createFolder } from '../../redux/modules/folder';
import { uploadFile } from '../../redux/modules/file';

const SidebarContainer = connect(
  state => ({
    sidebar: state.sidebar,
    user: state.user,
    viewport: state.viewport,
    folder: state.folder,
  }),
  { toggleSidebar, logoutUser, createUser, createFolder, uploadFile },
)(Sidebar);

export default withRouter(SidebarContainer);
