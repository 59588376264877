import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside'

import './SidebarDropdown.css';

class SidebarDropdown extends Component {
  handleClickOutside () {
    this.props.hideDropdown()
  }

  render () {
    const { className, uploadFile, path, token } = this.props
    return (
      <div className={className}>
        <div className='sidebar-padding'>
          <div className='sidebar-dropdown-option'>
            <span onClick={() => {
              this.props.togglePopup('folder')
              this.props.hideDropdown()
            }}>Folder</span>
          </div>
          <div className='sidebar-dropdown-option'>
            <label htmlFor='file-upload'><span>File</span></label>
            <input
              type='file'
              id='file-upload'
              accept='application/pdf'
              ref={el => this.fileInput = el}
              onChange={(e) => {
                this.props.hideDropdown()
                const file = this.fileInput.files[0]
                file && uploadFile(token, file, path)
              }}
            />
          </div>
          <hr />
          <div className='sidebar-dropdown-option'>
            <span onClick={() => {
              this.props.togglePopup('user')
              this.props.hideDropdown()
            }}>User</span>
          </div>
        </div>
      </div>
    )
  }
}

export default onClickOutside(SidebarDropdown)
