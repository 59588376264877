const START_LOADING = 'oms-frontend/status/START_LOADING'
const STOP_LOADING = 'oms-frontend/status/STOP_LOADING'

const initialState = {
  loading: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        ...{
          loading: [...state.loading, ...['loading']]
        }
      }
    case STOP_LOADING:
      return {
        ...state,
        ...{
          loading: state.loading.slice(1)
        }
      }
    default:
      return state
  }
}

export const startLoading = () => ({
  type: START_LOADING
})

export const stopLoading = () => ({
  type: STOP_LOADING
})
