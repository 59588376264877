const CHANGE_VIEWPORT = 'oms-frontend/viewport/CHANGE_VIEWPORT'

export default (state = 'desktop', action) => {
  switch (action.type) {
    case CHANGE_VIEWPORT:
      if (action.viewport < 768) return 'mobile'
      else return 'desktop'
    default:
      return state
  }
}

export const changeViewport = viewport => ({
  type: CHANGE_VIEWPORT,
  viewport
})
