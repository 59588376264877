import { connect } from 'react-redux';
import { Spinner } from '../../components';

const SpinnerContainer = connect(
  state => ({
    loading: state.status.loading
  })
)(Spinner);

export default SpinnerContainer;
