import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { DocumentRowDropdownContainer } from '../../../containers'
import { PopUpForm, ConfirmationWindow } from '../../'
import {ic_more_horiz} from 'react-icons-kit/md/ic_more_horiz'
import {ic_folder} from 'react-icons-kit/md/ic_folder'
import {ic_picture_as_pdf} from 'react-icons-kit/md/ic_picture_as_pdf'

import './DocumentRow.css'

class DocumentRow extends Component {
  constructor (props) {
    super(props)

    this.state = {
      fileUrl: '',
      dropdownActive: false,
      editFormActive: false,
      confirmationWindowActive: false
    }
  }

  toggleConfirmationWindow (bool) {
    this.setState({
      confirmationWindowActive: bool
    })
  }

  toggleEditDoc () {
    this.setState({
      editFormActive: true
    })
  }

  clearEditDoc () {
    this.setState({
      editFormActive: false
    })
  }

  hideDropdown () {
    this.setState({ dropdownActive: false })
  }

  componentDidMount () {
    const { name, type } = this.props.document
    const { path, token } = this.props
    if (type === 'folder') return
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/files/view?name=${encodeURIComponent(path ? path + '/' + name : name)}`,
      headers: {
        'Authorization': token
      }
    })
      .then(res => this.setState({ fileUrl: res.data.signedRequest }))
  }

  componentDidUpdate (prevProps) {
    const prevPath = prevProps.path + '/' + prevProps.document.name
    const currentPath = this.props.path + '/' + this.props.document.name
    if (prevPath !== currentPath) {
      const { name, type } = this.props.document
      const { path, token } = this.props
      if (type === 'folder') return
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/files/view?name=${encodeURIComponent(path ? path + '/' + name : name)}`,
        headers: {
          'Authorization': token
        }
      })
        .then(res => this.setState({ fileUrl: res.data.signedRequest }))
    }
  }

  render () {
    const { name, type, size, lastModified} = this.props.document
    const { path, token, renameFile, renameFolder, role, deleteFile, deleteFolder } = this.props
    const { dropdownActive, editFormActive, confirmationWindowActive } = this.state
    const lastModifiedString = moment.utc(new Date(lastModified)).format('MMM D YYYY')
    const stringifySize = (size) => {
      if (size >= 1000000) return Math.round(size / 1000000) + ' MB'
      else if (size >= 1000) return Math.round(size / 1000) + ' KB'
      else return size + ' B'
    }
    if (type === 'folder') return (
      <div className='document-row'>
        <div>
          <Icon icon={ic_folder} size={24} />
          <NavLink to={(`/view/${encodeURIComponent(path ? path + '/' : '')}${encodeURIComponent(name)}`)}>
            {name}
          </NavLink>
        </div>
        <div></div>
        <div></div>
        <div className='document-row-dropdown'>
          {role === 'admin' && <div className={dropdownActive ? 'document-row-dropdown-button-active' : 'document-row-dropdown-button'} onClick={() => this.setState({ dropdownActive: !dropdownActive })}>
            <Icon size={24} icon={ic_more_horiz} />
          </div>}
          {dropdownActive && <DocumentRowDropdownContainer hideDropdown={() => this.hideDropdown()} togglePopup={() => this.toggleEditDoc()} selectedFile={this.props.document} path={path} type={type} toggleConfirmationWindow={() => this.toggleConfirmationWindow(true)} />}
        </div>
        {editFormActive && <PopUpForm token={token} fileFolder={this.props.document} path={path} close={() => this.clearEditDoc()} submit={renameFolder} inputs={[
          {
            name: 'name',
            type: 'text'
          }
        ]} />}
        {confirmationWindowActive && <ConfirmationWindow
          message='Are you sure you want to delete this folder?'
          onSuccess={() => {
            deleteFolder(token, encodeURIComponent(path ? path + '/' + this.props.document.name : this.props.document.name))
          }}
          onCancel={() => this.toggleConfirmationWindow(false)}
        />}
      </div>
    )
    if (type === 'file') return (
      <div className='document-row'>
        <div onClick={() => {
          window.open(this.state.fileUrl)
        }}>
          <Icon icon={ic_picture_as_pdf} size={24} />
          {name}
        </div>
        <div>{lastModifiedString}</div>
        <div>{stringifySize(size)}</div>
        <div className='document-row-dropdown'>
          {role === 'admin' && <div className={dropdownActive ? 'document-row-dropdown-button-active' : 'document-row-dropdown-button'} onClick={() => this.setState({ dropdownActive: !dropdownActive })}>
            <Icon size={24} icon={ic_more_horiz} />
          </div>}
          {dropdownActive && <DocumentRowDropdownContainer hideDropdown={() => this.hideDropdown()} togglePopup={() => this.toggleEditDoc()} selectedFile={this.props.document} path={path} type={type} toggleConfirmationWindow={() => this.toggleConfirmationWindow(true)} />}
        </div>
        {editFormActive && <PopUpForm token={token} fileFolder={this.props.document} path={path} close={() => this.clearEditDoc()} submit={renameFile} inputs={[
          {
            name: 'name',
            type: 'text'
          }
        ]} />}
        {confirmationWindowActive && <ConfirmationWindow
          message='Are you sure you want to delete this file?'
          onSuccess={() => {
            deleteFile(token, encodeURIComponent(path ? path + '/' + this.props.document.name : this.props.document.name))
          }}
          onCancel={() => this.toggleConfirmationWindow(false)}
        />}
      </div>
    )
  }
}

export default DocumentRow
