import { connect } from 'react-redux';
import App from '../../App';
import { verifyUser } from '../../redux/modules/user';
import { changeViewport } from '../../redux/modules/viewport';

const AppContainer = connect(
  state => ({
    user: state.user,
    sidebar: state.sidebar,
  }),
  { verifyUser, changeViewport }
)(App);

export default AppContainer;
