import axios from 'axios'
import { toast } from "react-toastify";
import { startLoading, stopLoading } from './status'

const REQ_UPLOAD_FILE = 'oms-frontend/file/REQ_UPLOAD_FILE'
export const REC_UPLOAD_FILE = 'oms-frontend/file/REC_UPLOAD_FILE'
const REQ_GET_FILE = 'oms-frontend/file/REQ_GET_FILE'
const REC_GET_FILE = 'oms-frontend/file/REC_GET_FILE'
const REQ_DELETE_FILE = 'oms-frontend/file/REQ_DELETE_FILE'
export const REC_DELETE_FILE = 'oms-frontend/file/REC_DELETE_FILE'
const REQ_RENAME_FILE = 'oms-frontend/file/REQ_RENAME_FILE'
export const REC_RENAME_FILE = 'oms-frontend/file/REC_RENAME_FILE'

export const reqUploadFile = () => ({
  type: REQ_UPLOAD_FILE
})

export const recUploadFile = () => ({
  type: REC_UPLOAD_FILE
})

export const uploadFile = (token, file, folder) => {
  return dispatch => {
    dispatch(reqUploadFile())
    dispatch(startLoading())
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/files/upload?file-name=${encodeURIComponent(file.name)}&file-type=${file.type}${folder ? '&folder=' + encodeURIComponent(folder) : ''}`,
      headers: {
        'Authorization': token
      }
    })
      .then(res => {
        if (!res.data.err) {
          const signedRequest = res.data.signedRequest
          return axios.put(signedRequest, file, {
            headers: {
              'Content-Type': file.type
            }
          })
        } else {
          return res
        }
      })
      .then(res => {
        if (!res.data.err) {
          dispatch(recUploadFile())
          toast.success('Successfully uploaded ' + file.name)
          dispatch(stopLoading())
        } else {
          toast.error(res.data.err.error)
          dispatch(stopLoading())
        }
      })
  }
}

export const reqGetFile = () => ({
  type: REQ_GET_FILE
})

export const recGetFile = () => ({
  type: REC_GET_FILE
})

export const getFile = (token, path) => {
  return dispatch => {
    dispatch(reqGetFile())
    dispatch(startLoading())
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/files/view?name=${(path)}`,
      headers: {
        'Authorization': token
      }
    })
      .then(res => {
        if (!res.data.err) {
          window.open(res.data.signedRequest)
          dispatch(recGetFile())
          dispatch(stopLoading())
        }
      })
  }
}

export const reqDeleteFile = () => ({
  type: REQ_DELETE_FILE
})

export const recDeleteFile = () => ({
  type: REC_DELETE_FILE
})

export const deleteFile = (token, name) => {
  return dispatch => {
    dispatch(reqDeleteFile())
    dispatch(startLoading())
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/files/delete?name=${name}`,
      headers: {
        'Authorization': token
      }
    })
      .then(res => {
        if (!res.err) {
          dispatch(recDeleteFile())
          toast.success('Successfully deleted 1 file.')
          dispatch(stopLoading())
        } else {
          toast.error('Error encountered, please try again.')
          dispatch(stopLoading())
        }
      })
  }
}

export const reqRenameFile = () => ({
  type: REQ_RENAME_FILE
})

export const recRenameFile = () => ({
  type: REC_RENAME_FILE
})

export const renameFile = (token, initialPath, destinationPath) => {
  return dispatch => {
    dispatch(reqRenameFile())
    dispatch(startLoading())
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/files/rename?initial-path=${initialPath}&destination-path=${destinationPath}`,
      headers: {
        'Authorization': token
      }
    })
      .then(res => {
        if (!res.data.err) {
          dispatch(recRenameFile())
          toast.success('Successfully renamed 1 file.')
          dispatch(stopLoading())
        } else {
          toast.error(res.data.err.error || res.data.err)
          dispatch(stopLoading())
        }
      })
  }
}
