import React from 'react'
import { Icon } from 'react-icons-kit'
import { ic_menu } from 'react-icons-kit/md/ic_menu'

import logo from '../../assets/oms-logo.jpg'
import './Navbar.css'

const Navbar = ({ toggleSidebar, viewport, user }) => (
  <div className='navbar'>
    {viewport === 'mobile' && <Icon
      icon={ic_menu}
      size={24}
      onClick={() => toggleSidebar(true)}
    />}
    <img src={logo} alt='oms-logo' />
    <span>Service Reports</span>
    <span>Welcome, {user.user.name}</span>
  </div>
)

export default Navbar
